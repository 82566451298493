import React, { useState, useRef, useEffect } from 'react';
import Slider from 'react-slick';
import toast, { Toaster } from 'react-hot-toast';
import logo from "../../../assets/img/logo_stripe.png"
import { getDecryptedData, getEncryptedData, STRIPE_PUBLIC_KEY } from '../../../config';
import axios from 'axios';
import { Config } from '../../../config';
import CustomToast from './CustomToast';
import Loader from './Loader';
import PaymentModal from '../PaymentModal';
import { Elements  } from '@stripe/react-stripe-js';
import CheckoutForm from '../CheckOutForm';
import { Box, Typography } from '@mui/material';
import Slider1 from '@mui/material';
import RangeSlider from './RangeSlider';
import swal from 'sweetalert';
import { loadStripe } from '@stripe/stripe-js';

const GetCounsellingForm = () => {


    const [formData, setFormData] = useState({});

    const [isLoading, setLoading] = useState(false);
    const [showPayment, setShowPayment] = useState(false);
    const [total, setTotal] = useState(0);
    const [packageId, setPackageId] = useState(0);
    const [isModalOpen, setIsModalOpen] = useState(false);


  const [all_prices, setPrices] = useState({});
 

  useEffect(() => {
    if (Object?.keys(all_prices)?.length == 0) {
      setLoading(true);
      axios
        .get(`${Config.apiUrl}/sessionPrice`, {
          headers: {
            //'Authorization': 'Bearer ',
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          setLoading(false);
          setPrices(response?.data?.data[0]);
        })
        .catch((error) => {
          console.error("There was a problem fetching the data:", error);
          setLoading(false);
        });
    }
  }, []);

    const [personalDetails, setPersonalDetails] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        emergencyContact: '',
        dateofbirth: '',
        gender: '',
        emergencyName: '',
    });

    const [healthassesment, setHealthAssesment] = useState({
        primaryreason: '',
        impact: '',
        diagonised: '',
        ifDiagonised: '',
        currentlyseeing: '',
        suicide: '',
        ifSuicide: '',
        harmingyourself: '',
        ifHarmingyourself: '',
        experiencedpsychosis: '',
        ifExperiencedpsychosis: ''
    });

    const [otherDetails, setOtherDetails] = useState({
        healthinfo: '',
        medicalhistory: '',
        bookpackage: '',
        healthproblem: '',
    });

    const [paymentDetails, setPaymentDetails] = useState({
        booktypes: [],
        booktype: ''
    });

    const [errors, setErrors] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        emergencyContact: '',
        dateofbirth: '',
        gender: '',
        healthinfo: '',
        //bookpackage: '',
        healthproblem: '',
        booktype: '',
        emergencyName: '',
        primaryreason: '',
        impact: '',
        ifDiagonised: '',
    });

    const formContainerRef = useRef(null)

    const [currentSlide, setCurrentSlide] = useState(0);

    const customToastOptions = {
        style: {
            border: '1px transparent #ccc',
            padding: '27px 60px',
            backgroundColor: '#c19356',
            color: 'white',
            borderRadius: '10px',
            fontSize: '1.2rem',
            whiteSpace: 'nowrap',
            minWidth: '350px',
            fontWeight: '510',
            maxWidth: '80%',
            zIndex: 9999,
        },
        '@media (max-width: 700px)': {
            style: {
                padding: '1rem 2rem',
                fontSize: '1rem',
                width: '95%',
                maxWidth: '100%',
            }
        }
    };

    const settings = {
        dots: false,
        
        arrows: false,
        
        infinite: false,
        
        speed: 500,
        
        slidesToShow: 1,
        
        slidesToScroll: 1,
        
        swipe: false
        
    };

    const sliderRef = useRef(null);

    // const openPaymentModal = () => {
    //     setShowPayment(true);
    // };

    // const closePaymentModal = () => {
    //     setShowPayment(false);
    // };

    const capitalizeLabel = (label) => {
        return label.replace(/-/g, ' ').replace(/\b\w/g, (l) => l.toUpperCase());
    };

    const [quantities, setQuantities] = useState({
        'individual-counselling': 1,
        'couple-counselling': 1,
        'extra-session': 1,
        'pay-as-you-go': 1
    });

    const prices = {
        'individual-counselling': all_prices?.indivisual || "199",
        'couple-counselling': all_prices?.couples || "280",
        'extra-session': all_prices?.extra || "50",
        'pay-as-you-go': all_prices?.paygo || "65",
    };

    const bookTypeMapping = {
        'individual-counselling': 1,
        'couple-counselling': 2,
        'extra-session': 3,
        'pay-as-you-go': 4,
    };

    const bookTypeMapping1 = {
        'Individual Counselling': 1,
        'Couple Counselling': 2,
        'Extra Session': 3,
        'Pay As You Go': 4,
    };

    const [packageType, setPackageType] = useState({});
    const [price, setPrice] = useState({});
    const [count, setCount] = useState({});

    const handleChange = (e) => {
        const { name, value } = e.target;
        setHealthAssesment((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const [sliderValue, setSliderValue] = useState(1);

    const handleSliderChange = (newValue) => {
        setSliderValue(newValue);
        setHealthAssesment((prevState) => ({
            ...prevState,
            'impact': newValue,
        }));
    };

    const handleHealthCheckboxChange = (e) => {
        const { name, checked } = e.target;
        setHealthAssesment((prevState) => ({
            ...prevState,
            [name]: checked,
        }));
    };

    const handleRadioCheckboxChange = (e) => {
        const { name, value } = e.target;
        setHealthAssesment((prevState) => ({
            ...prevState,
            [name]: value,
        }));
        // console.log("healt", healthassesment);
    };

    const handleCheckboxChange = (e) => {
        const selectedValue = e.target.value;
        let updatedBooktypes = [...paymentDetails.booktypes];

        if (updatedBooktypes.includes(selectedValue)) {
            updatedBooktypes = updatedBooktypes.filter(item => item !== selectedValue);
        } else {
            updatedBooktypes.push(selectedValue);
        }

        if (!updatedBooktypes.includes('individual-counselling') && !updatedBooktypes.includes('couple-counselling')) {
            updatedBooktypes = updatedBooktypes.filter(item => item !== 'extra-session');
        }

        setPaymentDetails({
            ...paymentDetails,
            booktypes: updatedBooktypes,
            booktype: ''
        });

        updatePriceAndPackageId(updatedBooktypes);
    };

    const handleRadioChange = (e) => {
        const selectedValue = e.target.value;

        setPaymentDetails({
            booktypes: [],
            booktype: selectedValue,
        });

        updatePriceAndPackageId([selectedValue]);
    };

    const updatePriceAndPackageId = (selectedValues) => {
        const totalPrice = selectedValues.reduce((acc, curr) => acc + (prices[curr] || 0) * (quantities[curr] || 1), 0);
        setTotal(totalPrice);

        if (selectedValues.length === 1) {
            setPackageId(bookTypeMapping[selectedValues[0]]);
        } else {
            setPackageId(null);
        }
    };

    useEffect(() => {
        const calculateTotal = () => {
            let total = 0;
            if (paymentDetails.booktype) {
                total += prices[paymentDetails.booktype] * quantities[paymentDetails.booktype];
            } else {
                paymentDetails.booktypes.forEach((bookType) => {
                    total += prices[bookType] * quantities[bookType];
                });
            }
            setTotal(total.toFixed(2));
        };
        calculateTotal();
    }, [paymentDetails.booktypes, paymentDetails.booktype, prices, quantities]);

    const handleQuantityChange = (type, amount) => {
        if (paymentDetails.booktypes.includes(type) || paymentDetails.booktype === type) {
            setQuantities((prevQuantities) => {
                const newQuantity = (prevQuantities[type] || 1) + amount;
                return {
                    ...prevQuantities,
                    [type]: newQuantity > 0 ? newQuantity : 1,
                };
            });
        }
    };

    const isExtraSessionDisabled = !paymentDetails.booktypes.includes('individual-counselling') && !paymentDetails.booktypes.includes('couple-counselling');

    const generateCheckoutItems = () => {
        const items = paymentDetails.booktypes.map(type => {
            return {
                name: capitalizeLabel(type),
                quantity: quantities[type],
                price: prices[type],
                total: quantities[type] * prices[type]
            };
        });

        if (paymentDetails.booktype) {
            items.push({
                name: capitalizeLabel(paymentDetails.booktype),
                quantity: quantities[paymentDetails.booktype],
                price: prices[paymentDetails.booktype],
                total: quantities[paymentDetails.booktype] * prices[paymentDetails.booktype]
            });
        }

        return items;
    };

    const [isExtraSession, setIsExtraSession] = useState(false)
    const checkoutItems = generateCheckoutItems();

    //console.log("checkoutItems", checkoutItems);
    useEffect(() => {
        if (checkoutItems.some(item => (item.name == 'Couple Counselling' || item.name == 'Individual Counselling')))
            setIsExtraSession(true)
        else {
            setIsExtraSession(false)
        }
    }, [checkoutItems])

    const validatePersonalDetails = () => {
        const newErrors = {};

        if (!personalDetails.firstName) {
            newErrors.firstName = 'First Name is required';
        }

        if (!personalDetails.lastName) {
            newErrors.lastName = 'Last Name is required';
        }

        // const phoneRegex1 = /^[+\d]{7,15}$/;
        // if (!personalDetails.emergencyContact) {
        //     newErrors.emergencyContact = 'Emergency Contact Number is required';
        // } else if (!phoneRegex1.test(personalDetails.emergencyContact)) {
        //     newErrors.emergencyContact = 'Invalid Emergency Contact Number';
        // }

        const phoneRegex = /^[+\d]{7,15}$/;
        if (!personalDetails.phone) {
            newErrors.phone = 'Phone Number is required';
        } else if (!phoneRegex.test(personalDetails.phone)) {
            newErrors.phone = 'Invalid Phone Number';
        }

        if (!personalDetails.email) {
            newErrors.email = 'Email is required';
        } else if (!/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/.test(personalDetails.email)) {
            newErrors.email = 'Invalid email format';
        }

        // if (!personalDetails.dateofbirth) {
        //     newErrors.dateofbirth = 'Date of Birth is required';
        // }
        // if (!personalDetails.gender) {
        //     newErrors.gender = 'Gender is required';
        // }

        // if (!personalDetails.emergencyName) {
        //     newErrors.emergencyName = 'Emergency Contact Name is required.'
        // }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const validateHealthAssessment = () => {
        const newErrors = {};
        console.log(healthassesment.impact)

        if (!healthassesment.primaryreason) {
            newErrors.primaryreason = 'Primary reason for accessing therapy is required.';
        }

        if (!healthassesment.diagonised) {
            newErrors.diagonised = 'This Field is required.';
        } else if (healthassesment.diagonised === "yes") {
            if (!healthassesment.ifDiagonised) {
                newErrors.ifDiagonised = 'Conditions is required.';
            }
        }

        if (!healthassesment.currentlyseeing) {
            newErrors.currentlyseeing = 'This Field is required.';
        }
        if (!healthassesment.impact ) {
            newErrors.impact = 'This Field is required.';
        }

        if (!healthassesment.suicide) {
            newErrors.suicide = 'This Field is required.';
        } else if (healthassesment.suicide === "yes") {
            if (!healthassesment.ifSuicide) {
                newErrors.ifSuicide = 'This Field is required.';
            }
        }

        if (!healthassesment.harmingyourself) {
            newErrors.harmingyourself = 'This Field is required.';
        } else if (healthassesment.harmingyourself === "yes") {
            if (!healthassesment.ifHarmingyourself) {
                newErrors.ifHarmingyourself = 'This Field is required.';
            }
        }

        if (!healthassesment.experiencedpsychosis) {
            newErrors.experiencedpsychosis = 'This Field is required.';
        } else if (healthassesment.experiencedpsychosis === "yes") {
            if (!healthassesment.ifExperiencedpsychosis) {
                newErrors.ifExperiencedpsychosis = 'This Field is required.';
            }
        }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;

    };

    const validateMedicalDetails = () => {
        const newErrors = {};

        if (!otherDetails.healthinfo) {
            newErrors.healthinfo = 'Health Info is required';
        }
        // if (!otherDetails.bookpackage) {
        //     newErrors.bookpackage = 'Package Selection is required';
        // }
        if (!otherDetails.healthproblem) {
            newErrors.healthproblem = 'Health Problem Information is required';
        }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handlePersonalDetailsChange = (e) => {
        const { name, value } = e.target;
        setPersonalDetails({
            ...personalDetails,
            [name]: value,
        });
    };

    const handleOtherDetailsChange = (e) => {
        setOtherDetails({
            ...otherDetails,
            [e.target.name]: e.target.value,
        });
    };

    const handlePaymentDetailsChange = (e) => {
        const selectedValue = e.target.value;
        setPaymentDetails({
            ...paymentDetails,
            [e.target.name]: selectedValue,
        });

        const prices = {
            'individual-counselling': all_prices?.indivisual || "199",
            'couple-counselling': all_prices?.couples || "280",
            'extra-session': all_prices?.extra || "50",
            'pay-as-you-go': all_prices?.paygo || "65",
        };

        const bookTypeMapping = {
            'individual-counselling': 1,
            'couple-counselling': 2,
            'extra-session': 3,
            'pay-as-you-go': 4,
        };

        const price = prices[selectedValue];
        if (price !== undefined) {
            setTotal(price);
        } else {
            setTotal(null);
        }

        const packageid = bookTypeMapping[selectedValue];
        if (packageid !== undefined) {
            setPackageId(packageid);
        } else {
            setPackageId(null);
        }
    };

    const handleNextSlide = async () => {   
        if (currentSlide === 0) {
            
            if (validatePersonalDetails()) {
                setLoading(true)
                axios.post(`${Config.apiUrl}/checkEmail`, { email: personalDetails.email })
                    .then((response) => {
                        if (response.data.success === false && response.data.data === "email already taken") {
                            toast.error('This email is already taken !');
                            setErrors({ ...errors, email: "This email is already taken !" })
                        } else {
                            setCurrentSlide(currentSlide + 1);
                            sliderRef.current.slickNext();
                        }
                    })
                    .catch((err) => console.log(err))
                    .finally(() => setLoading(false))

            } else {
                toast.error('Please fill all required fields.');
            }
        }
        // else if (currentSlide === 1) {
        //     if (validateHealthAssessment()) {
        //         setCurrentSlide(currentSlide + 1);
        //         sliderRef.current.slickNext();
        //     } else {
        //         toast.error('Please fill all required fields.');
        //     }
        // }
        // else if (currentSlide === 2) {
        //     if (validateMedicalDetails()) {
        //         setCurrentSlide(currentSlide + 1);
        //         sliderRef.current.slickNext();
        //     } else {
        //         toast.error('Please fill all required fields.');
        //     }
        // }
        else {
            setCurrentSlide(currentSlide + 1);
            console.log(currentSlide)
            sliderRef.current.slickNext();
        }
        // formContainerRef.current.scrollTo(0, 0)
        // window.scrollTo(0, 2300)
    };

    const handlePrevSlide = () => {
        setCurrentSlide(currentSlide - 1);
        sliderRef.current.slickPrev();
        // formContainerRef.current.scrollTo(0, 0)
        // window.scrollTo(0, 2300)
    };

    const handleValidation = () => {

        const newErrors = {};

        if (!personalDetails.firstName) {
            newErrors.firstName = 'First Name is required';
        }

        if (!personalDetails.lastName) {
            newErrors.lastName = 'Last Name is required';
        }

        // if (!personalDetails.emergencyContact) {
        //     newErrors.emergencyContact = 'Emergency Contact is required';
        // } else if (!/^[+\d]{7,15}$/.test(personalDetails.emergencyContact)) {
        //     newErrors.emergencyContact = 'Invalid Emergency Contact';
        // }

        // const phoneRegex = /^(?:(?:(?:\+|00)44\s?(?:\(\d{1,5}\)|\d{1,5})\s?)|(?:0\d{1,4}))\s?\d{5,10}$/;
        // if (!personalDetails.phone) {
        //     newErrors.phone = 'Phone Number is required';
        // } else if (!/^[+\d]{7,15}$/.test(personalDetails.phone)) {
        //     newErrors.phone = 'Invalid Phone Number';
        // }

        if (!personalDetails.email) {
            newErrors.email = 'Email is required';
        } else if (!/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(personalDetails.email)) {
            newErrors.email = 'Invalid email format';
        }

        // if (!personalDetails.dateofbirth) {
        //     newErrors.dateofbirth = 'Date of Birth is required';
        // }
        // if (!personalDetails.gender) {
        //     newErrors.gender = 'Gender is required';
        // }
        // if (!otherDetails.healthinfo) {
        //     newErrors.healthinfo = 'Health Info is required';
        // }
        // if (!otherDetails.bookpackage) {
        //     newErrors.bookpackage = 'Package Selection is required';
        // }
        // if (!otherDetails.healthproblem) {
        //     newErrors.healthproblem = 'Health Problem Information is required';
        // }
        if (!paymentDetails.booktypes.length && !paymentDetails.booktype) {
            newErrors.booktype = 'Booking Type selection is required';
        }

        // if (!healthassesment.primaryreason) {
        //     newErrors.primaryreason = 'Primary reason for accessing therapy is required.';
        // }
        // if (healthassesment.diagonised === "yes") {
        //     if (!healthassesment.ifDiagonised) {
        //         newErrors.ifDiagonised = 'Conditions is required.';
        //     }
        // }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    }

    const clearFields = () => {
        setPersonalDetails({
            firstName: '',
            lastName: '',
            email: '',
            phone: '',
            emergencyContact: '',
            dateofbirth: '',
            gender: '',
        });

        setOtherDetails({
            healthinfo: '',
            medicalhistory: '',
            //bookpackage: '',
            healthproblem: '',
        });

        setPaymentDetails({
            booktype: ''
        });
    };

    const calculateFinalTotal = () => {
        const packageTypeArray = checkoutItems.map(item => bookTypeMapping1[item.name]);
        const priceArray = checkoutItems.map(item => item.total);
        const countArray = checkoutItems.map(item => {
            if (item.name === 'Individual Counselling' || item.name === 'Couple Counselling') {
                return 4;
            }
            return item.quantity;
        });

        const packageTypeString = `${packageTypeArray.join(',')}`;
        const priceString = `${priceArray.join(',')}`;
        const countString = `${countArray.join(',')}`;

        setPackageType(packageTypeString);
        setPrice(priceString);
        setCount(countString);

        return {
            packageTypeString,
            priceString,
            countString,
        };
    };

    const [userId, setUserId] = useState();

    const stripePayment = loadStripe(STRIPE_PUBLIC_KEY);

    const handlePaySubmit = async (e) => {
        e.preventDefault();
        if (handleValidation()) {   
            swal({
                icon:'warning',
                title:"Are You sure?",
                text:"You Want to Pay Now"
            }).then(async (isConfirm) => {
                if(isConfirm){

            const { packageTypeString, priceString, countString } = calculateFinalTotal();

            //console.log("packageType, price, count", packageTypeString, priceString, countString);

            const data1 = {
                name: getEncryptedData(personalDetails.firstName),
                last_name: getEncryptedData(personalDetails.lastName),
                emergency_contact: getEncryptedData(personalDetails.emergencyContact),
                emergency_contact_name: getEncryptedData(personalDetails.emergencyName),
                phone: getEncryptedData(personalDetails.phone),
                email: personalDetails.email,
                birthdate: getEncryptedData(personalDetails.dateofbirth),
                gender: getEncryptedData(personalDetails.gender),
                previous_services: getEncryptedData(otherDetails.healthinfo),
                current_health_issue: getEncryptedData(otherDetails.healthproblem),
                medical_history: getEncryptedData(otherDetails.medicalhistory),
                //therapy_type: getEncryptedData(otherDetails.bookpackage),
                //booking_type: paymentDetails.booktype,
                payment_type: 'stripe',
                package_id: packageId,
                booking_type: packageTypeString,
                price: priceString,
                count: countString,
                userId: userId,
                impact: sliderValue,
                primaryreason: getEncryptedData(healthassesment.primaryreason),
                diagonised: getEncryptedData(healthassesment.diagonised),
                ifDiagonised: getEncryptedData(healthassesment.ifDiagonised),
                currentlyseeing: getEncryptedData(healthassesment.currentlyseeing),
                suicide: healthassesment.suicide,
                ifSuicide: getEncryptedData(healthassesment.ifSuicide),
                harmingyourself: getEncryptedData(healthassesment.harmingyourself),
                ifHarmingyourself: getEncryptedData(healthassesment.ifHarmingyourself),
                experiencedpsychosis: getEncryptedData(healthassesment.experiencedpsychosis),
                ifExperiencedpsychosis: getEncryptedData(healthassesment.ifExperiencedpsychosis),
            };
            setFormData(data1);
            // setIsModalOpen(true);
            try {
                setLoading(true)
                const personalResponse = await axios.post(`${Config.apiUrl}/addPersonal`, {
                    name: data1.name,
                    last_name: data1.last_name,
                    emergency_contact: data1.emergency_contact,
                    emergency_contact_name: data1.emergency_contact_name,
                    phone: data1.phone,
                    email: data1.email,
                    birthdate: data1.birthdate,
                    gender: data1.gender,
                    type: 3
                });

                if (personalResponse.data.success) {

                    const response = await axios.post(`${Config.apiUrl}/createPayment`, {
                        amount: parseFloat(total).toFixed(2),
                        total_amount: total,
                        booking_type: data1.booking_type,
                        price: data1.price,
                        count: data1.count,
                        user_id: personalResponse.data.data.id
                    });

                    //console.log("addPersonal api Response",personalResponse);

                    if (response.status === 200) {
                        // const userId = personalResponse.data.data.id;
                        // const medicalResponse = await axios.post(`${Config.apiUrl}/addMedical`, {
                        //     user_id: personalResponse.data.data.id,
                        //     previous_services: data1.previous_services,
                        //     current_health_issue: data1.current_health_issue,
                        //     medical_history: data1.medical_history,
                        //     therapy_type: data1.therapy_type,
                        //     primary_reason: data1.primaryreason,
                        //     impact: data1.impact,
                        //     mental_health_history: data1.diagonised,
                        //     condition: data1.ifDiagonised,
                        //     seeing_professionals: data1.currentlyseeing,
                        //     suicide_thoughts: data1.suicide,
                        //     planned_to_act: data1.ifSuicide,
                        //     harming_self: data1.harmingyourself,
                        //     acted_lastweek: data1.ifHarmingyourself,
                        //     psychosis: data1.experiencedpsychosis,
                        //     last_12_months: data1.ifExperiencedpsychosis,
                        //     name_string: getDecryptedData(data1.name),
                        //     attempt: (getDecryptedData(data1.ifSuicide) === 'yes' ||
                        //         getDecryptedData(data1.ifHarmingyourself) === 'yes') ? 1 : 0
                        // });
                        //console.log("addMedical api Response",medicalResponse);

                        // if (medicalResponse.data.success) {
                            const paymentResponse = await axios.post(`${Config.apiUrl}/addPayment`, {
                                booking_type: data1.booking_type,
                                payment_type: data1.payment_type,
                                payment_id: response.data.clientSecret?.id,
                                payment_status: 'unpaid',
                                package_id: data1.package_id,
                                price: data1.price,
                                count: data1.count,
                                user_id: personalResponse.data.data.id,
                                total_amount: total
                            });
                            
                                if (paymentResponse.data.success) {
                                    const stripe = await stripePayment;
                                    const responsePayment = await axios.post(`${Config.apiUrl}/createCheckoutSession`, {
                                        amount:total,
                                        user_id: personalResponse.data.data.id,
                                        payment_id: response?.data?.payment_id,
                                    });
                                    setLoading(false)
                                    const session = await responsePayment?.data;

                                    // Redirect to Stripe Checkout
                                    const result = await stripe.redirectToCheckout({
                                        sessionId: session.id,
                                    });

                                    if (result.error) {
                                        // Handle error here
                                        swal({
                                        icon: "error",
                                        title: "Payment Failed",
                                        text: result.error.message,
                                        icon: "error",
                                        });
                                        console.error(result.error.message);
                                    }
                                }
                            //console.log("addPayment api Response", paymentResponse);

                            // if (paymentResponse.data.success) {
                            //     toast.success(
                            //         <div id="jeanonlinecustomerform">
                            //             Registered Successfully!
                            //             <br />
                            //             Please check your mail.
                            //         </div>,
                            //         { duration: 12000 }
                            //     );
                            //     setTimeout(() => {
                            //         setLoading(false);
                            //         window.location.reload();
                            //     }, 12000);
                            // } else {
                            //     toast.error('Something Went Wrong!');
                            // }
                        // } else {
                        //     toast.error('Something Went Wrong!');
                        // }
                    }
                    else {
                        toast.error(
                            <div>
                                Something Went Wrong!
                                <br />
                                Payment Not Successful.
                            </div>,
                            { autoClose: 8000 }
                        );
                    }
                }
                else if (personalResponse.data.data === 'email already taken') {
                    toast.error('This email is already taken!', {
                        iconTheme: {
                            primary: '#c19356',
                            secondary: '#c19356',
                        },
                    });
                }
                else {
                    toast.error('Something Went Wrong!');
                    toast.error(
                        <div>
                            Something Went Wrong!
                            <br />
                            Payment Not Successful.
                        </div>,
                        { autoClose: 8000 }
                    );
                }

                setLoading(false)
            } catch (err) {
                console.log(err)
                setLoading(false)
            }
            finally {
                setLoading(false);
            }
        }
    })
        

        }
        else {

            toast.error('Please fill all required fields.');
        }
    };

    const ShowPaymentPopup = () => {
        setShowPayment(true);
    }

    const openModal = () => {
        toast.success(
            <div>
                Registered Successfully!
                <br />
                Please check your mail.
            </div>,
            { duration: 5000 }
        );
        //setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    return (
        <>
            <Toaster className="toast" position="top-right" reverseOrder={false} toastOptions={customToastOptions} />
            {isLoading && <Loader />}
            <div className='customer-form1' ref={formContainerRef}>
                <Slider ref={sliderRef} {...settings}>
                    {/* Slide 1: Personal Details */}
                    <div >
                        <div className='' data-aos="fade-in">
                            <h1>Personal Details</h1>
                        </div>
                        <div className='filldet-form2' data-aos="fade-in">
                            <div className='row'>
                                <div className='col-sm-6'>
                                    <p>
                                        <label>First Name  <i className='text-danger'>*</i></label><br />
                                        <span>
                                            <input className='form-control' value={personalDetails.firstName} type='text'
                                                name="firstName"
                                                placeholder='Enter Name'
                                                onKeyPress={(e) => {
                                                    if (/[0-9]/.test(e.key)) {
                                                        e.preventDefault();
                                                    }
                                                }}
                                                onChange={handlePersonalDetailsChange}
                                            ></input>
                                            {
                                                errors.firstName && <span className='text-danger'>{errors.firstName}</span>
                                            }
                                        </span>
                                    </p>
                                </div>
                                <div className='col-sm-6'>
                                    <p>
                                        <label>Last Name <i className='text-danger'>*</i></label><br />
                                        <span>
                                            <input className='form-control' value={personalDetails.lastName}
                                                name="lastName"
                                                type='text'
                                                onKeyPress={(e) => {
                                                    if (/[0-9]/.test(e.key)) {
                                                        e.preventDefault();
                                                    }
                                                }}
                                                placeholder='Enter Last Name'
                                                onChange={handlePersonalDetailsChange}
                                            ></input>
                                            {
                                                errors.lastName && <span className='text-danger'>{errors.lastName}</span>
                                            }
                                        </span>
                                    </p>
                                </div>
                                <div className='col-sm-6' >
                                    <p>
                                        <label>Email <i className='text-danger'>*</i></label><br />
                                        <span>
                                            <input className='form-control' value={personalDetails.email}
                                                name="email"
                                                type='text' placeholder='Enter Email'
                                                onChange={handlePersonalDetailsChange}
                                            ></input>
                                            {
                                                errors.email && <span className='text-danger'>{errors.email}</span>
                                            }
                                        </span>
                                    </p>
                                </div>
                                <div className='col-sm-6'>
                                    <p>
                                        <label>Phone Number <i className='text-danger'>*</i></label><br />
                                        <span>
                                            <input className='form-control' value={personalDetails.phone}
                                                name="phone"
                                                type='text' placeholder='Enter Phone Number'
                                                onKeyPress={(e) => {
                                                    if (!/[0-9]/.test(e.key)) {
                                                        e.preventDefault();
                                                    }
                                                }}
                                                onChange={handlePersonalDetailsChange}
                                            ></input>
                                            {
                                                errors.phone && <span className='text-danger'>{errors.phone}</span>
                                            }
                                        </span>
                                    </p>
                                </div>
                                {/* <div className='col-sm-6'>
                                    <p>
                                        <label>Date of Birth <i className='text-danger'>*</i></label><br />
                                        <span>
                                            <input className='form-control' value={personalDetails.dateofbirth}

                                                name="dateofbirth"
                                                type='date' placeholder='Enter Emergency Contact'
                                                onChange={handlePersonalDetailsChange}
                                            ></input>
                                            {
                                                errors.dateofbirth && <span className='text-danger'>{errors.dateofbirth}</span>
                                            }
                                        </span>
                                    </p>
                                </div>
                                <div className='col-sm-6'>
                                    <p>
                                        <label>Gender <i className='text-danger'>*</i></label><br />
                                        <span className='custom-select'>
                                            <select className='form-control' value={personalDetails.gender}
                                                name='gender' onChange={handlePersonalDetailsChange}>
                                                <option value="">--- Select ---</option>
                                                <option value="Male">Male</option>
                                                <option value="Female">Female</option>
                                                <option value="Other">Other</option>
                                                </select>
                                            {
                                                errors.gender && <span className='text-danger'>{errors.gender}</span>
                                            }
                                        </span>
                                    </p>
                                </div>

                                <div className='col-sm-6'>
                                    <p>
                                        <label>Emergency Contact Name <i className='text-danger'>*</i></label><br />
                                        <span>
                                            <input className='form-control' value={personalDetails.emergencyName}
                                                name="emergencyName"
                                                type='text' placeholder='Enter Emergency Contact Name'
                                                onKeyPress={(e) => {
                                                    if (/[0-9]/.test(e.key)) {
                                                        e.preventDefault();
                                                    }
                                                }}
                                                onChange={handlePersonalDetailsChange}
                                            ></input>
                                            {
                                                errors.emergencyName && <span className='text-danger'>{errors.emergencyName}</span>
                                            }
                                        </span>
                                    </p>
                                </div>

                                <div className='col-sm-6'>
                                    <p>
                                        <label>Emergency Contact Number <i className='text-danger'>*</i></label><br />
                                        <span>
                                            <input className='form-control' value={personalDetails.emergencyContact}
                                                name="emergencyContact"
                                                type='text' placeholder='Enter Emergency Contact Number'
                                                onChange={handlePersonalDetailsChange}
                                                onKeyPress={(e) => {
                                                    if (!/[0-9]/.test(e.key)) {
                                                        e.preventDefault();
                                                    }
                                                }}
                                            ></input>
                                            {
                                                errors.emergencyContact && <span className='text-danger'>{errors.emergencyContact}</span>
                                            }
                                        </span>
                                    </p>
                                </div> */}
                                <div className='col-sm-12 next-btn'>
                                    <div>
                                        <a onClick={handleNextSlide}>Proceed to Payment <i className='bi bi-arrow-right'></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Slide 2: Health Assesment */}
                    {/* <div>   
                        <div className='' data-aos="fade-in">
                            <h1>Health Assesment</h1>
                        </div>
                        <div className='filldet-form2' data-aos="fade-in">
                            <div className='row'>
                                <span className='title2 mb-4'>Presenting Concerns</span>
                                <div className='col-sm-6'>
                                    <p>
                                        <label>Primary reason for accessing therapy : <i className='text-danger'>*</i></label><br />
                                        <span>
                                            <textarea rows={2} className='form-control' value={healthassesment.primaryreason} type='text'
                                                name="primaryreason"
                                                placeholder='Add reason here..'
                                                onChange={handleChange}
                                            ></textarea>
                                            {
                                                errors.primaryreason && <span className='text-danger'>{errors.primaryreason}</span>
                                            }
                                        </span>
                                    </p>
                                </div>
                                <div className='col-sm-6' >
                                    <p>
                                        <label>Impact of these issues on daily life (scale of 1-10, 1 being minimal and 10 being severe) : <i className='text-danger'>*</i></label><br />
                                        <RangeSlider onSliderChange={handleSliderChange} />
                                        {
                                                errors.impact && <span className='text-danger'>{errors.impact}</span>
                                            }
                                    </p>
                                </div>

                                <span className='title2 mb-3 mt-3'>Mental Health History</span>
                                <div className='col-sm-6'>
                                    <p>
                                        <label>Have you ever been diagnosed with a mental health condition? <i className='text-danger'>*</i></label><br />
                                        <span className="radio-container1">
                                            <div className='div1'>
                                                <input type="checkbox"
                                                    id="mentalhealthY"
                                                    name="diagonised"
                                                    value="yes"
                                                    checked={healthassesment.diagonised === 'yes'}
                                                    onChange={handleRadioCheckboxChange}
                                                    className='checkbox-effect checkbox-effect-5' />
                                                <label className='label2' htmlFor="mentalhealthY">Yes</label>

                                                <input type="checkbox"
                                                    id="mentalhealthN"
                                                    name="diagonised"
                                                    value="no"
                                                    checked={healthassesment.diagonised === 'no'}
                                                    onChange={handleRadioCheckboxChange}
                                                    className='checkbox-effect checkbox-effect-5' />
                                                <label className='label2' htmlFor="mentalhealthN">No</label>
                                            </div>
                                        </span>
                                        {
                                            errors.diagonised && <span className='text-danger'>{errors.diagonised}</span>
                                        }
                                    </p>
                                </div>
                                <div className={`col-sm-6 ${healthassesment.diagonised === 'yes' ? 'visible' : 'hidden'}`}>
                                    <p>
                                        <label className='mb-5'>If yes, please specify the condition(s) : <i className='text-danger'>*</i></label><br />
                                        <span>
                                            <textarea className='form-control' value={healthassesment.ifDiagonised} type='text'
                                                name="ifDiagonised"
                                                placeholder=''
                                                onChange={handleChange}
                                            ></textarea>
                                            {
                                                errors.ifDiagonised && <span className='text-danger'>{errors.ifDiagonised}</span>
                                            }
                                        </span>
                                    </p>
                                </div>

                                <div className='col-sm-6'>
                                    <p>
                                        <label>Are you currently seeing any other mental health professionals? <i className='text-danger'>*</i></label><br />
                                        <span className="radio-container1">
                                            <div className='div1'>
                                                <input type="checkbox"
                                                    id="currentlyseeingY"
                                                    name="currentlyseeing"
                                                    value="yes"
                                                    checked={healthassesment.currentlyseeing === 'yes'}
                                                    onChange={handleRadioCheckboxChange}
                                                    className='checkbox-effect checkbox-effect-5' />
                                                <label className='label2' htmlFor="currentlyseeingY">Yes</label>

                                                <input type="checkbox"
                                                    id="currentlyseeingN"
                                                    name="currentlyseeing"
                                                    value="no"
                                                    checked={healthassesment.currentlyseeing === 'no'}
                                                    onChange={handleRadioCheckboxChange}
                                                    className='checkbox-effect checkbox-effect-5' />
                                                <label className='label2' htmlFor="currentlyseeingN">No</label>
                                            </div>
                                        </span>
                                        {
                                            errors.currentlyseeing && <span className='text-danger'>{errors.currentlyseeing}</span>
                                        }
                                    </p>
                                </div>

                                <>
                                    <span className='title2 mb-3 mt-3'>Risk</span>
                                    <div className='col-sm-6'>
                                        <p>
                                            <label>Have you had thoughts of suicide in the last 4 weeks? <i className='text-danger'>*</i></label><br />
                                            <span className="radio-container1">
                                                <div className='div1'>
                                                    <input type="checkbox"
                                                        id="suicideY"
                                                        name="suicide"
                                                        value="yes"
                                                        checked={healthassesment.suicide === 'yes'}
                                                        onChange={handleRadioCheckboxChange}
                                                        className='checkbox-effect checkbox-effect-5' />
                                                    <label className='label2' htmlFor="suicideY">Yes</label>

                                                    <input type="checkbox"
                                                        id="suicideN"
                                                        name="suicide"
                                                        value="no"
                                                        checked={healthassesment.suicide === 'no'}
                                                        onChange={handleRadioCheckboxChange}
                                                        className='checkbox-effect checkbox-effect-5' />
                                                    <label className='label2' htmlFor="suicideN">No</label>
                                                </div>
                                            </span>
                                            {
                                                errors.suicide && <span className='text-danger'>{errors.suicide}</span>
                                            }
                                        </p>
                                    </div>


                                    <div className={`col-sm-6 ${healthassesment.suicide === 'yes' ? 'visible' : 'hidden'}`}>
                                        <p>
                                            <label>If yes, have you acted on or planned to act on these thoughts? <i className='text-danger'>*</i></label><br />
                                            <span className="radio-container1">
                                                <div className='div1'>
                                                    <input type="checkbox"
                                                        id="ifSuicideY"
                                                        name="ifSuicide"
                                                        value="yes"
                                                        checked={healthassesment.ifSuicide === 'yes'}
                                                        onChange={handleRadioCheckboxChange}
                                                        className='checkbox-effect checkbox-effect-5' />
                                                    <label className='label2' htmlFor="ifSuicideY">Yes</label>

                                                    <input type="checkbox"
                                                        id="ifSuicideN"
                                                        name="ifSuicide"
                                                        value="no"
                                                        checked={healthassesment.ifSuicide === 'no'}
                                                        onChange={handleRadioCheckboxChange}
                                                        className='checkbox-effect checkbox-effect-5' />
                                                    <label className='label2' htmlFor="ifSuicideN">No</label>
                                                </div>
                                            </span>
                                            {
                                                errors.ifSuicide && <span className='text-danger'>{errors.ifSuicide}</span>
                                            }
                                        </p>
                                    </div>


                                    <div className='col-sm-6'>
                                        <p>
                                            <label>Do you have thoughts of harming yourself or others? <i className='text-danger'>*</i></label><br />
                                            <span className="radio-container1">
                                                <div className='div1'>
                                                    <input type="checkbox"
                                                        id="harmingyourselfY"
                                                        name="harmingyourself"
                                                        value="yes"
                                                        checked={healthassesment.harmingyourself === 'yes'}
                                                        onChange={handleRadioCheckboxChange}
                                                        className='checkbox-effect checkbox-effect-5' />
                                                    <label className='label2' htmlFor="harmingyourselfY">Yes</label>

                                                    <input type="checkbox"
                                                        id="harmingyourselfN"
                                                        name="harmingyourself"
                                                        value="no"
                                                        checked={healthassesment.harmingyourself === 'no'}
                                                        onChange={handleRadioCheckboxChange}
                                                        className='checkbox-effect checkbox-effect-5' />
                                                    <label className='label2' htmlFor="harmingyourselfN">No</label>
                                                </div>
                                            </span>
                                            {
                                                errors.harmingyourself && <span className='text-danger'>{errors.harmingyourself}</span>
                                            }
                                        </p>
                                    </div>

                                    <div className={`col-sm-6 ${healthassesment.harmingyourself === 'yes' ? 'visible' : 'hidden'}`}>
                                        <p>
                                            <label>If Yes have you acted on or planned to act on these thoughts in the last 4 weeks? <i className='text-danger'>*</i></label><br />
                                            <span className="radio-container1">
                                                <div className='div1'>
                                                    <input type="checkbox"
                                                        id="ifHarmingyourselfY"
                                                        name="ifHarmingyourself"
                                                        value="yes"
                                                        checked={healthassesment.ifHarmingyourself === 'yes'}
                                                        onChange={handleRadioCheckboxChange}
                                                        className='checkbox-effect checkbox-effect-5' />
                                                    <label className='label2' htmlFor="ifHarmingyourselfY">Yes</label>

                                                    <input type="checkbox"
                                                        id="ifHarmingyourselfN"
                                                        name="ifHarmingyourself"
                                                        value="no"
                                                        checked={healthassesment.ifHarmingyourself === 'no'}
                                                        onChange={handleRadioCheckboxChange}
                                                        className='checkbox-effect checkbox-effect-5' />
                                                    <label className='label2' htmlFor="ifHarmingyourselfN">No</label>
                                                </div>
                                            </span>
                                            {
                                                errors.ifHarmingyourself && <span className='text-danger'>{errors.ifHarmingyourself}</span>
                                            }
                                        </p>
                                    </div>

                                    <div className='col-sm-6'>
                                        <p>
                                            <label>Have you ever experienced psychosis and/or hallucinations <i className='text-danger'>*</i></label><br />
                                            <span className="radio-container1">
                                                <div className='div1'>
                                                    <input type="checkbox"
                                                        id="experiencedpsychosisY"
                                                        name="experiencedpsychosis"
                                                        value="yes"
                                                        checked={healthassesment.experiencedpsychosis === 'yes'}
                                                        onChange={handleRadioCheckboxChange}
                                                        className='checkbox-effect checkbox-effect-5' />
                                                    <label className='label2' htmlFor="experiencedpsychosisY">Yes</label>

                                                    <input type="checkbox"
                                                        id="experiencedpsychosisN"
                                                        name="experiencedpsychosis"
                                                        value="no"
                                                        checked={healthassesment.experiencedpsychosis === 'no'}
                                                        onChange={handleRadioCheckboxChange}
                                                        className='checkbox-effect checkbox-effect-5' />
                                                    <label className='label2' htmlFor="experiencedpsychosisN">No</label>
                                                </div>
                                            </span>
                                            {
                                                errors.experiencedpsychosis && <span className='text-danger'>{errors.experiencedpsychosis}</span>
                                            }
                                        </p>
                                    </div>

                                    <div className={`col-sm-6 ${healthassesment.experiencedpsychosis === 'yes' ? 'visible' : 'hidden'}`}>
                                        <p>
                                            <label>If Yes has this been within the last 12 months? <i className='text-danger'>*</i></label><br />
                                            <span className="radio-container1">
                                                <div className='div1'>
                                                    <input type="checkbox"
                                                        id="ifExperiencedpsychosisY"
                                                        name="ifExperiencedpsychosis"
                                                        value="yes"
                                                        checked={healthassesment.ifExperiencedpsychosis === 'yes'}
                                                        onChange={handleRadioCheckboxChange}
                                                        className='checkbox-effect checkbox-effect-5' />
                                                    <label className='label2' htmlFor="ifExperiencedpsychosisY">Yes</label>

                                                    <input type="checkbox"
                                                        id="ifExperiencedpsychosisN"
                                                        name="ifExperiencedpsychosis"
                                                        value="no"
                                                        checked={healthassesment.ifExperiencedpsychosis === 'no'}
                                                        onChange={handleRadioCheckboxChange}
                                                        className='checkbox-effect checkbox-effect-5' />
                                                    <label className='label2' htmlFor="ifExperiencedpsychosisN">No</label>
                                                </div>
                                            </span>
                                            {
                                                errors.ifExperiencedpsychosis && <span className='text-danger'>{errors.ifExperiencedpsychosis}</span>
                                            }
                                        </p>
                                    </div>

                                </>

                                <div className='col-sm-12 prev-btn'>
                                    <div>
                                        <a onClick={handlePrevSlide}><i className='bi bi-arrow-left me-2'></i>Personal Details</a>
                                    </div>

                                    <div><a onClick={handleNextSlide}>Medical Details <i className='bi bi-arrow-right'></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}

                    {/* Slide 3: Other Details */}
                    {/* <div>
                        <div className='' data-aos="fade-in">
                            <h1>Medical Details</h1>
                        </div>
                        <div className='filldet-form2' data-aos="fade-in">
                            <div className='row'>
                                <div className='col-sm-12'>
                                    <p>
                                        <label>Have you previously received any type of mental health services (psychotherapy, psychiatric services, etc.)? <i className='text-danger'>*</i></label><br />
                                        <span>
                                            <textarea className='form-control' value={otherDetails.healthinfo} type='text'
                                                name="healthinfo"
                                                placeholder='Enter here..'
                                                onChange={handleOtherDetailsChange}
                                            ></textarea>
                                            {
                                                errors.healthinfo && <span className='text-danger'>{errors.healthinfo}</span>
                                            }
                                        </span>
                                    </p>
                                </div>
                                <div className='col-sm-12' >
                                    <p>
                                        <label>Please list any specific health problems you are currently experiencing <i className='text-danger'>*</i></label><br />
                                        <span>
                                            <textarea className='form-control' value={otherDetails.healthproblem}
                                                name="healthproblem"
                                                type='text' placeholder='Enter here..'
                                                onChange={handleOtherDetailsChange}
                                            ></textarea>
                                            {
                                                errors.healthproblem && <span className='text-danger'>{errors.healthproblem}</span>
                                            }
                                        </span>
                                    </p>
                                </div>
                                <div className='col-sm-6'>
                                    <p>
                                        <label>Medical History if Any  </label><br />
                                        <span>
                                            <input className='form-control' value={otherDetails.medicalhistory} type='text'
                                                name="medicalhistory"
                                                placeholder='Medical History if Any..'
                                                onChange={handleOtherDetailsChange}
                                            ></input>
                                        </span>
                                    </p>
                                </div>
                                <div className='col-sm-12 prev-btn'>
                                    <div>
                                        <a onClick={handlePrevSlide}><i className='bi bi-arrow-left me-2'></i>Health Details</a>
                                    </div>

                                    <div><a onClick={handleNextSlide}>Payment Details <i className='bi bi-arrow-right'></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}

                    {/* Slide 4: Payment Details */}
                    <div>
                        <div className='' data-aos="fade-in">
                            <h1>Payment Details</h1>
                        </div>
                        <div className='filldet-form2' data-aos="fade-in">
                            <div className='row'>
                                <div className='col-sm-6'>
                                    <p>
                                        <label>Booking Type <i className='text-danger'>*</i></label><br />
                                        <span className="radio-container1">
                                            <div>
                                                <div>
                                                    <input type="checkbox" value="individual-counselling"
                                                        id="individual-counselling"
                                                        name="booktype"
                                                        checked={paymentDetails.booktypes.includes('individual-counselling')}
                                                        onChange={handleCheckboxChange}
                                                        className='checkbox-effect checkbox-effect-5' />
                                                    <label htmlFor="individual-counselling">£{all_prices?.indivisual || "199"} [ Individual Counselling ]</label>
                                                </div>
                                                {/* <div className='checkout-div'>
                                                    <button className='checkout-btn' onClick={() => handleQuantityChange('individual-counselling', -1)}>-</button>
                                                    <button className='checkout-inp'>{quantities['individual-counselling']}</button>
                                                    <button className='checkout-btn' onClick={() => handleQuantityChange('individual-counselling', 1)}>+</button>
                                                </div> */}

                                            </div>

                                            <div>
                                                <div>
                                                    <input type="checkbox" value="couple-counselling"
                                                        id="couple-counselling"
                                                        name="booktype"
                                                        checked={paymentDetails.booktypes.includes('couple-counselling')}
                                                        onChange={handleCheckboxChange}
                                                        className='checkbox-effect checkbox-effect-5'
                                                    />
                                                    <label htmlFor="couple-counselling">£{all_prices?.couples || "280"} [ Couple Counselling ]</label>
                                                </div>
                                                {/* <div className='checkout-div'>
                                                    <button className='checkout-btn' onClick={() => handleQuantityChange('couple-counselling', -1)}>-</button>
                                                    <button className='checkout-inp'>{quantities['couple-counselling']}</button>
                                                    <button className='checkout-btn' onClick={() => handleQuantityChange('couple-counselling', 1)}>+</button>
                                                </div> */}
                                            </div>
                                            {
                                                isExtraSession &&
                                                <div>
                                                    <div>
                                                        <input type="checkbox" value="extra-session"
                                                            id="extra-session"
                                                            name="booktype"
                                                            checked={paymentDetails.booktypes.includes('extra-session')}
                                                            onChange={handleCheckboxChange}
                                                            disabled={isExtraSessionDisabled}
                                                            className='checkbox-effect checkbox-effect-5'
                                                        />
                                                        <label htmlFor="extra-session">£{all_prices?.extra || "50"} [ Extra Session ]</label>
                                                    </div>
                                                    {paymentDetails.booktypes.includes('extra-session') &&
                                                        <div className='checkout-div'>
                                                            <button className='checkout-btn' onClick={() => handleQuantityChange('extra-session', -1)}>-</button>
                                                            <button className='checkout-inp'>{quantities['extra-session']}</button>
                                                            <button className='checkout-btn' onClick={() => handleQuantityChange('extra-session', 1)}>+</button>
                                                        </div>
                                                    }
                                                </div>
                                            }


                                        </span>

                                        <span className="radio-container1">
                                            <div>
                                                <div>
                                                    <input type="checkbox" value="pay-as-you-go"
                                                        id="pay-as-you-go"
                                                        name="booktype"
                                                        checked={paymentDetails.booktype === 'pay-as-you-go'}
                                                        onChange={handleRadioChange}
                                                        className='checkbox-effect checkbox-effect-5'
                                                    />
                                                    <label htmlFor="pay-as-you-go">£{all_prices?.paygo || "65"} [ Pay-As-You-Go ]</label>
                                                </div>
                                                {paymentDetails.booktype === 'pay-as-you-go' && (
                                                    <div className='checkout-div'>
                                                        <button className='checkout-btn' onClick={() => handleQuantityChange('pay-as-you-go', -1)}>-</button>
                                                        <button className='checkout-inp'>{quantities['pay-as-you-go']}</button>
                                                        <button className='checkout-btn' onClick={() => handleQuantityChange('pay-as-you-go', 1)}>+</button>
                                                    </div>
                                                )}
                                            </div>
                                        </span>
                                        {
                                            errors.booktype && <span className='text-danger'>{errors.booktype}</span>
                                        }
                                    </p>
                                </div>

                                {checkoutItems.length > 0 ? (
                                    <div className='col-sm-6 checkout-cart'>
                                        <h2>Booking Summary</h2>
                                        <span className='checkout'>
                                            <div>
                                                <div className='checkout-total'>
                                                    {checkoutItems.map(item => (
                                                        <div>
                                                            <div className='chk-total-div' key={item.name}>
                                                                <div className='chk-div'>
                                                                    {item.name === 'Individual Counselling' &&
                                                                        <div>
                                                                            <h3>Individual Counselling</h3>

                                                                            <span>( 4 Sessions Per Month )</span>
                                                                        </div>}
                                                                    {item.name === 'Couple Counselling' &&
                                                                        <div>
                                                                            <h3>Couple Counselling</h3>

                                                                            <span>( 4 Sessions Per Month )</span>
                                                                        </div>}
                                                                    {item.name === 'Extra Session' &&
                                                                        <div>
                                                                            <h3>( Extra Session [ 50 &times; {item.quantity} ] )</h3>
                                                                        </div>}
                                                                    {item.name === 'Pay As You Go' &&
                                                                        <div>
                                                                            <h3>( Pay As You Go [ 65 &times; {item.quantity} ] )</h3>
                                                                        </div>}
                                                                </div>
                                                                <div>
                                                                    {item.name === 'Individual Counselling' &&
                                                                        <div className='font-bold'>
                                                                            £ {item.total}
                                                                        </div>}
                                                                    {item.name === 'Couple Counselling' &&
                                                                        <div className='font-bold'>
                                                                            £ {item.total}
                                                                        </div>}
                                                                    {item.name === 'Extra Session' &&
                                                                        <div className='font-bold'>
                                                                            £ {item.total}
                                                                        </div>}
                                                                    {item.name === 'Pay As You Go' &&
                                                                        <div className='font-bold'>
                                                                            £ {item.total}
                                                                        </div>}
                                                                </div>

                                                            </div>
                                                            <hr style={{ border: '1px dashed white' }} />
                                                        </div>
                                                    ))}
                                                    {/* <hr /> */}
                                                    <div className='final-total'>
                                                        <span className='span1'>Total Amount To Pay </span>
                                                        <span className='span1'>£ {total}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </span>
                                    </div>
                                ) : (
                                    <div className='col-sm-6 checkout-cart'>
                                        <h2>Booking Summary</h2>
                                        <span className='checkout'>
                                            <div>
                                                <div className='checkout-total'>
                                                    {/* <hr /> */}
                                                    <div style={{ padding: '10px' }}>
                                                        <span className='span1'>No Booking Summary Found</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </span>
                                    </div>
                                )
                                }

                                <div className='col-sm-7'>
                                    <div className='col-sm-10'>
                                        <p>
                                            <label>Payment type</label><br />
                                            <span className="radio-container1">
                                                <div>
                                                    <div>
                                                        <input type="checkbox" value="session"
                                                            name=""
                                                            checked={true}
                                                            onChange={handlePaymentDetailsChange}
                                                            className='checkbox-effect checkbox-effect-5'
                                                        />
                                                        <label style={{ display: 'flex', alignItems: 'center' }}>
                                                            <img src={logo} alt='Payment Logo'
                                                                className='selected-animation stripe-img'
                                                            ></img>
                                                        </label>
                                                        {/* <img src={logo} alt='Payment Logo'
                                                            className='selected-animation'
                                                        ></img> */}
                                                    </div>
                                                </div>
                                            </span>
                                            {/* <span className="radio-container">
                                                <input type="radio" value="session"
                                                    name="" checked={true}
                                                    onChange={handlePaymentDetailsChange}
                                                     />
                                                <img src={logo} alt='Payment Logo'
                                                    className='selected-animation'
                                                ></img>
                                            </span> */}
                                        </p>
                                    </div>
                                </div>

                                <div>
                                    <div className='submit-btn col-sm-8'>
                                        <p>
                                            <button onClick={handlePaySubmit}>Pay Now</button>
                                        </p>
                                        {/* <p>
                                            <button onClick={openModal}>Pay Stripe</button>
                                        </p> */}
                                    </div>
                                </div>

                                <div className='col-sm-12 prev-btn'>
                                    <div>
                                        <a onClick={handlePrevSlide}><i className='bi bi-arrow-left me-2'></i>Medical Details</a></div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <PaymentModal isOpen={isModalOpen} onClose={closeModal} total={total} data={formData} />
                    {/* {showPayment && (
                        <div className="modal">
                            <div className="modal-content">
                                <button className="close-button">X</button>
                                <Elements stripe={stripePromise}>
                                    <CheckoutForm MainTotal={total} />
                                </Elements>
                            </div>
                        </div>
                    )} */}
                </Slider>
            </div>
        </>
    );
};

export default GetCounsellingForm;
