import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "bootstrap-icons/font/bootstrap-icons.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./pages/layouts/Home/index";
import { Config } from "./config";
import ContactUs from "./pages/layouts/ContactUs";
import AboutUs from "./pages/layouts/AboutUs";
import PrivacyPolicy from "./pages/layouts/PrivacyPolicy";
import Blog from "./pages/layouts/Blog";
import BlogDetails from "./pages/components/BlogDetails";
import GetStarted from "./pages/layouts/GetStarted";
import TherapistJob from "./pages/layouts/TherapistJob";
import TermsAndCondition from "./pages/layouts/TermsAndCondition";
// import ScrollToTop from "./pages/components/ScrollToTop";
import FAQ from "./pages/layouts/FAQ";
import BookNow from "./pages/layouts/BookNow";
import TherapistDetail from "./pages/components/Common/TherapistDetail";
import { useEffect, useState } from "react";
import NotFoundPage from "./pages/components/NotFoundPage";
import PaymentSuccess from "./pages/layouts/Payment/PaymentSuccess";
import PaymentFailed from "./pages/layouts/Payment/PaymentFailed";
import SecureLogon from "./pages/components/Common/SecureLogon";
import Request from "./pages/components/Request";

function App() {
  useEffect(() => {
    document.documentElement.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant",
    });
  }, []);

  return (
    <div className="App">
      <Router>
        <Routes>
          {/* <Route path={`${Config.appUrl}login`} Component={SecureLogon} /> */}
          <Route path={`${Config.appUrl}`} Component={Home} />
          <Route path={`${Config.appUrl}contactus`} Component={ContactUs} />
          <Route path={`${Config.appUrl}aboutus`} Component={AboutUs} />
          <Route
            path={`${Config.appUrl}privacy-policy`}
            Component={PrivacyPolicy}
          />
          <Route path={`${Config.appUrl}blog`} Component={Blog} />
          <Route
            path={`${Config.appUrl}blogdetail/:slug`}
            Component={BlogDetails}
          />
          <Route path={`${Config.appUrl}getstarted`} Component={GetStarted} />
          <Route
            path={`${Config.appUrl}why-join-the-therapy-team`}
            Component={TherapistJob}
          />
          <Route
            path={`${Config.appUrl}terms-and-condition`}
            Component={TermsAndCondition}
          />
          <Route path={`${Config.appUrl}faq`} Component={FAQ} />
          <Route path={`${Config.appUrl}get-counselling`} Component={BookNow} />
          <Route
            path={`${Config.appUrl}therapistdetail/:slug`}
            Component={TherapistDetail}
          />
          <Route path={`${Config.appUrl}success`} Component={PaymentSuccess} />
          <Route path={`${Config.appUrl}failed`} Component={PaymentFailed} />
          <Route path="*" Component={NotFoundPage} />
        </Routes>
      </Router>

      <div>
        <Request />
      </div>
    </div>
  );
}

export default App;
