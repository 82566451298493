import axios from "axios";
import React, { useState } from "react";
import { Config } from "../../config";
import toast from "react-hot-toast";
import Loader from "./Common/Loader";

const Request = () => {

  const [isOpen, setIsOpen] = useState(false)

  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [message, setMessage] = useState('')
  const [isLoading, setLoading] = useState(false)
  const [errors, setErrors] = useState({
    email: "",
  });

  const handleValidation = () => {
    const newErrors = {};
    if (!email) {
      newErrors.email = "Invalid email format";
    } else if (
      !/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email)
    ) {
      newErrors.email = "Invalid email format";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (handleValidation()) {
      const data = new FormData();
      data.append("name", name);
      data.append("phone", phone);
      data.append("email", email);
      data.append("message", message);
      setLoading(true);
      axios
        .post(`${Config.apiUrl}/websiteEnquiry`, data, {
          headers: {
            //'Authorization': 'Bearer ',
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          const data = response.data;
          if (data.success === true) {
            toast.success(<div>Message Sent Successfully!</div>, {
              duration: 5000,
            });
            setName("");
            setPhone("");
            setEmail("");
            setMessage("");
            setIsOpen(false)
          } else {
            toast.error(
              <div>
                Something Went Wrong!
                <br />
                Please try again.
              </div>
            );
          }
          setLoading(false);
        })
        .catch((error) => {
          toast.error("Something Went Wrong!");
          console.error("There was a problem fetching the data:", error);
          setLoading(false);
        });
    }
  };



  return (
    <div className="position-realtive">
      {isLoading && <Loader />}
      <div className="floating-btn">
        <a className="request-btn" onClick={() => setIsOpen(!isOpen)}>
          <i className="bi bi-chat-dots-fill"></i>

        </a>
      </div>
      {isOpen && (
        <div className="ps-request-box">
          <div>

            <form onSubmit={(e) => handleSubmit(e)}>
              <div class="form-top ">
                <a className="close-btn" onClick={() => setIsOpen(false)}>
                  <i className="bi bi-x"></i>
                </a>
                <div class="col-sm-12 name-col">
                  <p>
                    <label>Full Name</label>
                    <input
                      class="form-control"
                      type="text"
                      placeholder="Enter Full Name"
                      required
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </p>
                </div>
                <div class="col-sm-12">
                  <p>
                    <label>Email Address</label>
                    <input
                      class="form-control"
                      type="email"
                      placeholder="Enter Email Address"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      required
                    />
                    {errors.email && (
                      <span className="" style={{ fontSize: "14px", color: "#ffb1b1" }}>
                        {errors.email}
                      </span>
                    )}
                  </p>
                </div>
                <div class="col-sm-12">
                  <p>
                    <label>Phone Number </label>
                    <input
                      class="form-control"
                      type="text"
                      placeholder="Enter Phone Number"
                      required
                      value={phone}

                      onChange={(e) => setPhone(e.target.value)}
                      onKeyPress={(e) => {
                        if (!/[0-9]/.test(e.key)) {
                          e.preventDefault();
                        }
                      }}
                    />
                  </p>
                </div>
                <div class="col-sm-12">
                  <p>
                    <label>Things to Discuss Regarding :</label>
                    <select className="request-select"
                      onChange={(e) => setMessage(e.target.value)}>
                      <option>Select an option</option>
                      <option value='Stress'>Stress</option>
                      <option value="Anxiety">Anxiety</option>
                      <option value="Depression">Depression</option>
                      <option value="other">Other</option>
                    </select>

                  </p>
                </div>
                <div class="col-sm-6 button">
                  <p>
                    <input class="form-control" type="submit" style={{
                      backgroundColor:
                        "rgb(0, 182, 188)", color: "white"
                    }} value="Submit" />
                  </p>
                </div>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default Request;
